<!-- 隐私协议页面 -->
<template>
  <div>隐私协议</div>
</template>

<script>
export default {
  data () {
    return {
    };
  },
  methods: {}
}

</script>
<style lang='scss' scoped>
</style>